@import "../../styles/basic.scss";
.login-container {
	.login-section {
		.login-header {
			& > .primary-title {
				margin-bottom: 8px;
			}
			& > .secondary-title {
				line-height: 1.5;
				color: #637381;
			}
		}
		.login-form {
			.field-wrapper {
				border: none;
				margin: 20px 0;
			}
		}
	}
	@media (min-width: 600px) {
		.login-section {
			max-width: 600px;
			width: 100%;
			margin: auto;
		}
	}
}
