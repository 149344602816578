//Theme colors

$primary-theme-green: #00ab55;
$secondary-gradient-green: #03cc67;
// Phones
@mixin sm {
	@media screen and (max-width: 767px) {
		@content;
	}
}

// Tablets
@mixin md {
	@media screen and (max-width: 1279px) {
		@content;
	}
}

@mixin printOrMd {
	@media print, screen and (max-width: 1279px) {
		@content;
	}
}

 .css-s3yb5j{
	margin-top: 10px !important;
 }
.css-f1hklk-MuiGrid-root{
	width: 70% !important;
	margin-top: 10px !important;
    margin-left: 115px !important;
}
.currency{
	font-size: 25px;
}

.react-tel-input .form-control{
	width: 537px !important;
}

.form-control:focus{
	border-color:#00ab55 !important;
	box-shadow:0 0 0 1px #00ab55 !important;
}

.css-1c1zmpt-MuiPaper-root-MuiAutocomplete-paper{
	width: 500px !important;
}

.currency-text{
	position: relative;
	z-index: 1;
}
.currency-img{
	position: absolute;
	z-index: 2;
	padding-top: 18px !important;
	margin-left: 15px !important;

}

.css-1ln7e51-MuiGrid-root{
    padding: 35px;
    margin: 25px;
    border-width: 1px;
    border-radius: 10px;
    //box-shadow: rgb(0 0 0 / 35%) 0px 5px 15px;
	box-shadow: rgb(174, 233, 174) 0px 3px 8px;
	background: #c8facd;
   
}
.pb{
	padding-bottom: 10px;
}

.send-amount  .MuiOutlinedInput-root{
	border-radius: 8px 0px 0px 8px !important;
}
.send-flag  .MuiOutlinedInput-root{
	border-radius: 0px 8px 8px 0px !important;
}

.receive-amount  .MuiOutlinedInput-root{
	border-radius: 8px 0px 0px 8px !important;
}
.receive-flag  .MuiOutlinedInput-root{
	border-radius: 0px 8px 8px 0px !important;
}

.send-amount .MuiInputBase-input{
	padding: 8.5px 14px !important;
	font-size: 27px;
    font-weight: 600;
}

.receive-amount .MuiInputBase-input{
	padding: 8.5px 14px !important;
	font-size: 27px;
    font-weight: 600;
}
.appbar-text{
	flex-grow: 1;
	text-align: center;
}

.css-pow92c{
	padding: 10px 0px !important;
}
.card-shadow{
	box-shadow: #d3dad4 0px 2px 1px -1px, #d3dad4 0px 1px 1px 0px, #d3dad4 0px 1px 3px 0px;
}
.arrow-size
{
	font-size: 15px !important;
}
.center{
	text-align: center !important;
}

.css-hgthaq-MuiListItemIcon-root{
	width: 35px !important;
}
.empty-image{
	text-align: center;
}
.empty-image-container {
	display: flex;
	justify-content: center;
	align-items: center;
 }
.green-card{
	background-color: #c8facd;
	box-shadow: #d3dad4 0px 2px 1px -1px, #d3dad4 0px 1px 1px 0px, #d3dad4 0px 1px 3px 0px;
}
.white-card{
	background-color: #fff;
	box-shadow: #d3dad4 0px 2px 1px -1px, #d3dad4 0px 1px 1px 0px, #d3dad4 0px 1px 3px 0px;
}

.green-button{
	color:#00ab55;
}

.grey-button{
	color:#dde0df
}
.full-width{
width:100%;
}

.in-progress-image {
	padding-top:40px;
	display: flex;
	justify-content: center;
	align-items: center;
 }

 .rate-heading{
	text-align: center;
	padding:10px;
	color:#5d5c5b;
 }

 .empty-transaction-image {
	padding-top:40px;
	display: flex;
	justify-content: center;
	align-items: center;
	align-self: center;
 }